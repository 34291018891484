import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import MainLayOut from "./component/MainLayOut";
import Home from "./pages/Home";
import { useEffect } from "react";
import Login from "./pages/login/Login.jsx";
import { useDispatch, useSelector } from "react-redux";
import VehicleCreate from "./pages/Vehicle-magangement/VehicleCreate.jsx";
import GoodsType from "./pages/Goods-Management/GoodsType.jsx";
import GoodsTypeList from "./pages/Goods-Management/GoodsTypeList.jsx";
import { goodsTypeList } from "./redux/GoodsType/GoodsSlice.js";
import UpdateGoodsType from "./pages/Goods-Management/UpdateGoodsType.jsx";
import { allVehicleList } from "./redux/Vehicle/VehicleSlice.js";
import VehicleList from "./pages/Vehicle-magangement/VehicleList.jsx";
import {
  allNotVerifiedVehicleList,
  verifiedVehicleList,
} from "./redux/Partner/PartnerSlice.js";
import NotVerifiedPartner from "./pages/Partner-Management/NotVerifiedPartner.jsx";
import VerifiedPartner from "./pages/Partner-Management/VerifiedPartner.jsx";
import NewDriveRequest from "./pages/Drive-School/NewDriveRequest.jsx";
import SeenDriveRequest from "./pages/Drive-School/SeenDriveRequest.jsx";
import DeletedDriveRequest from "./pages/Drive-School/DeletedDriveRequest.jsx";
import { GetNewRequest } from "./redux/DriveSchool/DriveSchoolSlice.js";
import RentalRequest from "./pages/Rental-Service/RentalRequest.jsx";
import SeenRentalRequest from "./pages/Rental-Service/SeenRentalRequest.jsx";
import CancelledRentalRequest from "./pages/Rental-Service/CancelledRentalRequest.jsx";
import {
  getAllGoodsDeliveryRequests,
  normalRideRequests,
} from "./redux/Rides/RidesSlice.js";
import NormalRideRequest from "./pages/Rides/NormalRides.jsx";
import GoodsDeliveryRide from "./pages/Rides/GoodsDeliveryRide.jsx";
import VerifiedPartnerRides from "./pages/Rides/RidesByDriver.jsx";
import GoodsDeliveryRider from "./pages/Rides/GoodsDeliveryByDriver.jsx";

function ScrollToTop() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { goodsTypeTotal } = useSelector((store) => store.goodsType);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const initialSetup = async () => {
      try {
        dispatch(goodsTypeList());
        dispatch(allVehicleList());
        dispatch(allNotVerifiedVehicleList());
        dispatch(verifiedVehicleList());
        dispatch(GetNewRequest());
        // dispatch(getAllRentalRequests());
        dispatch(normalRideRequests());
        dispatch(getAllGoodsDeliveryRequests());
      } catch (error) {}
    };
    initialSetup();
  }, []);
  useEffect(() => {
    if (!goodsTypeTotal) {
      dispatch(goodsTypeList());
    }
  }, []);

  return null;
}

function App() {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/admin" exact element={<MainLayOut />}>
            <Route index element={<Home />} />
            <Route path="/admin/addvehicle" element={<VehicleCreate />} />
            <Route path="/admin/vehiclelist" element={<VehicleList />} />
            <Route path="/admin/goodstype" element={<GoodsType />} />
            <Route path="/admin/goodstypelist" element={<GoodsTypeList />} />
            <Route
              path="/admin/notverifiedpartnerlist"
              element={<NotVerifiedPartner />}
            />
            <Route
              path="/admin/verifiedpartnerlist"
              element={<VerifiedPartner />}
            />
            <Route
              path="/admin/updategoodstype/:_id"
              element={<UpdateGoodsType />}
            />
            <Route
              path="/admin/driveschool/newrequests"
              element={<NewDriveRequest />}
            />
            <Route
              path="/admin/driveschool/seenrequests"
              element={<SeenDriveRequest />}
            />
            <Route
              path="/admin/driveschool/deletedrequests"
              element={<DeletedDriveRequest />}
            />
            <Route
              path="/admin/vehicle-rental/newrequests"
              element={<RentalRequest />}
            />
            <Route
              path="/admin/vehicle-rental/seenrequests"
              element={<SeenRentalRequest />}
            />
            <Route
              path="/admin/vehicle-rental/deletedrequests"
              element={<CancelledRentalRequest />}
            />
            <Route
              path="/admin/rides/normalrides"
              element={<NormalRideRequest />}
            />
            <Route
              path="/admin/ridesByPartner/:riderId"
              element={<VerifiedPartnerRides />}
            />
            <Route
              path="/admin/goodsDeliveryByPartner/:riderId"
              element={<GoodsDeliveryRider />}
            />
            <Route
              path="/admin/rides/goods-delivery-rides"
              element={<GoodsDeliveryRide />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
