import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Button } from "antd";
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { api_key } from "../../config/BaseUrl";
import { useParams } from "react-router-dom";
import { stringify } from "jodit/esm/core/helpers";

const GoodsDeliveryRider = () => {
    const [filterData, setFilterData] = useState([]);
    const { goodsDeliveryRides } = useSelector((store) => store.rides);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRide, setSelectedRide] = useState(null);
    const [totalAmt, setTotalAmt] = useState(0)
    const params = useParams()

    useEffect(() => {
        const filteredData = goodsDeliveryRides.filter((item) => item.deliveryBoyId === params.riderId && item.status === "completed")
        setFilterData(filteredData)
        const TAmt = filteredData.reduce((accumulator, currentValue) => {
            const travelCost = parseFloat(currentValue.travelCost.replace(/[^0-9.-]+/g, ""));  // Remove currency symbols and convert to a number
            return accumulator + (isNaN(travelCost) ? 0 : travelCost);  // Add 0 if travelCost is not a valid number
        }, 0);
        setTotalAmt(TAmt);
    }, [params.riderId, goodsDeliveryRides])


    const showModal = (data) => {
        setSelectedRide(data);
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <div className="min-h-screen flex flex-col lg:ps-64 w-full">
                <div className="p-6 space-y-6">
                    <div className="flex items-center justify-between w-full print:hidden">
                        <h4 className="text-lg font-semibold text-default-900">
                            Dashboard
                        </h4>
                        <div style={{ fontWeight: '600', color: '#000' }}>
                            Total Rides:<span style={{ paddingLeft: '10px', color: '#000', fontWeight: '700' }}>{filterData.length}</span>
                        </div>
                        <div style={{ fontWeight: '600', color: '#000' }}>
                            Total Amount:<span style={{ paddingLeft: '10px', color: '#000', fontWeight: '700' }}>{totalAmt}</span>
                        </div>
                        <div style={{ fontWeight: '600', color: '#000' }}>
                            Total Commission:<span style={{ paddingLeft: '10px', color: '#000', fontWeight: '700' }}>    {Math.ceil(totalAmt * 0.2)}</span>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1">
                    <div className="shadow rounded-lg bg-white dark:bg-default-50" style={{ marginBottom: '50px' }}>
                        <div className="border-t border-dashed border-default-200">
                            <div className="relative overflow-x-auto">
                                <table className="min-w-full overflow-x-hidden">
                                    <thead className="border-b border-dashed border-default-200">
                                        <tr>
                                            <th className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-16">No</th>
                                            <th className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-40">Name</th>
                                            <th className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-40">Mobile</th>
                                            <th className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-40">Rider Name</th>
                                            <th className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-40">Rider Mobile</th>
                                            <th className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-40">Origin</th>
                                            <th className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-40">Destination</th>
                                            <th className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-40">Cost</th>
                                            <th className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-40">Status</th>
                                            <th className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-40">Details</th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-dashed divide-default-200">
                                        {filterData &&
                                            filterData.map((data, index) => (
                                                <tr key={index}>
                                                    <td className="px-6 py-3 text-default-600 font-semibold whitespace-nowrap"><b>{index + 1}</b></td>
                                                    <td className="px-6 py-3 whitespace-nowrap">
                                                        <h6 className="text-sm font-semibold text-default-700">
                                                            {data?.pickupName.length > 30 ? `${data?.pickupName.slice(0, 30)}...` : data?.pickupName}
                                                        </h6>
                                                    </td>
                                                    <td className="px-6 py-3 text-default-600 font-semibold whitespace-nowrap"><b>{data?.pickupMobile}</b></td>
                                                    <td className="px-6 py-3 whitespace-nowrap">
                                                        <h6 className="text-sm font-semibold text-default-700">
                                                            {data?.deliveryBoyName}
                                                        </h6>
                                                    </td>
                                                    <td className="px-6 py-3 text-default-600 font-semibold whitespace-nowrap"><b>{data?.deliveryBoyPhone}</b></td>
                                                    <td className="px-6 py-3 text-default-600 font-semibold whitespace-nowrap">
                                                        {data?.origin.length > 30 ? `${data?.origin.slice(0, 30)}...` : data?.origin}
                                                    </td>
                                                    <td className="px-6 py-3 text-default-600 font-semibold whitespace-nowrap">
                                                        {data?.destination.length > 30 ? `${data?.destination.slice(0, 30)}...` : data?.destination}
                                                    </td>
                                                    <td className="px-6 py-3 text-default-600 font-semibold whitespace-nowrap"><b>{data?.travelCost}</b></td>
                                                    <td className="px-6 py-3 text-default-600 font-semibold whitespace-nowrap" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <span style={{
                                                            backgroundColor: data?.status === "completed" ? '#00b300' :
                                                                data?.status === "cancelled" ? 'red' : '#ff9933',
                                                            color: '#fff',
                                                            padding: '5px',
                                                            borderRadius: '2px',
                                                            textAlign: 'center'
                                                        }}>
                                                            {data?.status}
                                                        </span>
                                                    </td>
                                                    <td className="px-6 py-3 text-default-600 font-semibold whitespace-nowrap">
                                                        {data?.status === "completed"
                                                            ? <b style={{ textDecoration: 'underline', color: '#1a53ff' }} onClick={() => showModal(data)}>View Details</b>
                                                            : <b>No Details Found</b>}
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="flex flex-wrap items-center justify-between gap-6 py-3 px-6 border-t border-dashed border-default-200">
                            <h6 className="text-default-600">Showing {filterData.length} results</h6>
                        </div>
                    </div>
                </div>
                <Modal
                    title="Ride Details"
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={[
                        <Button key="cancel" style={{ padding: "10px 15px", color: "#fff", backgroundColor: "#FF0000" }} onClick={handleCancel}>Cancel</Button>,
                        <Button key="ok" style={{ padding: "10px 15px", color: "#fff", backgroundColor: "#04681e" }} onClick={handleOk}>Ok</Button>
                    ]}
                >
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div >
                            <div><span style={{ fontWeight: '600', color: '#000' }}>BookingID : </span><span>{selectedRide?._id}</span></div>
                            <div><span style={{ fontWeight: '600', color: '#000' }}>Traveller Name:</span><span>{selectedRide?.pickupName}</span></div>
                            <div><span style={{ fontWeight: '600', color: '#000' }}>Rider Name:</span><span>{selectedRide?.deliveryBoyName}</span></div>
                            <div><span style={{ fontWeight: '600', color: '#000' }}>PickUp Location:</span><div><span>{selectedRide?.origin}</span></div></div>
                            <div><span style={{ fontWeight: '600', color: '#000' }}>Destination Location:</span><div><span>{selectedRide?.destination}</span></div></div>
                            <div><span style={{ fontWeight: '600', color: '#000' }}>Vehicle Type:</span><div><span>{selectedRide?.vehicleType?.name}</span></div></div>
                            <div><span style={{ fontWeight: '600', color: '#000' }}>Travel Cost:</span><div><span>{selectedRide?.travelCost}</span></div></div>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    )
}

export default GoodsDeliveryRider;
