import React from "react";
import { FaChevronRight } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Dashboard from "./Dashboard";

const Home = () => {
  const { newRequest } = useSelector((store) => store.driveSchool);
  const { newRentalRequest } = useSelector((store) => store.vehicleRental);
  const { goodsDeliveryRides, normalRides } = useSelector(
    (store) => store.rides
  );
  const { verifiedPartner, notVerifiedPartner } = useSelector(
    (store) => store.partner
  );
  return (
    <div className="min-h-screen flex flex-col lg:ps-64 w-full">
      <Dashboard />
    </div>
  );
};

export default Home;
