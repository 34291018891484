import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../redux/Authentication/AuthSlice";
import { MdAdminPanelSettings } from "react-icons/md";
import { TbDeviceIpadMinus } from "react-icons/tb";
import { GrUserAdmin } from "react-icons/gr";
import { FaFileArchive } from "react-icons/fa";
import { FaFileAlt } from "react-icons/fa";
import { CiFileOn } from "react-icons/ci";
import { RiFileCopy2Fill } from "react-icons/ri";
import { IoMdPerson } from "react-icons/io";
import { GoChecklist } from "react-icons/go";
import { IoIosImages } from "react-icons/io";
import { TbReportSearch } from "react-icons/tb";
import { FaBook } from "react-icons/fa6";
import { CiBellOn } from "react-icons/ci";
import { MdOutlinePersonAddAlt } from "react-icons/md";
import { FaListUl } from "react-icons/fa";
import { TfiWrite } from "react-icons/tfi";
import { IoCarSportOutline } from "react-icons/io5";
const Hadear = () => {
  const { loginData, role } = useSelector((store) => store.auth);

  const [unseenNotification, setUnseenNotification] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [misReport, setShowMisReport] = useState(false)
  const [showVehicle, setShowVehicle] = useState(false)
  const [showGoods, setShowGoods] = useState(false)
  const [drivingSchool, setDrivingSchool] = useState(false)
  const [rentalService, setRentalService] = useState(false)
  const [rideService, setRideService] = useState(false)
  const [showPartner, setShowPartner] = useState(false)
  const [showCase, setShowCase] = useState(false)
  const [showReport, setShowReport] = useState(false)

  const [toggle, setToggle] = useState(false);

  const tableRef = useRef(null);
  const [isFullScreen, setFullScreen] = useState(false);


  const handleFullScreen = () => {
    if (!isFullScreen) {
      // Request fullscreen
      document.documentElement
        .requestFullscreen()
        .then(() => setFullScreen(!isFullScreen))
        .catch((err) =>
          console.error("Error attempting to enable full-screen mode", err)
        );
    } else {
      // Exit fullscreen
      document
        .exitFullscreen()
        .then(() => setFullScreen(!isFullScreen))
        .catch((err) =>
          console.error("Error attempting to exit full-screen mode", err)
        );
    }
  };

  const handleEscapeKey = () => {
    setFullScreen(false);
    console.log("hii manoj");
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleEscapeKey();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const logoutClick = () => {
    dispatch(authActions.signout());
    navigate("/");
  };
  return (
    <>
      <div
        className="min-h-screen flex flex-col lg:ps-64 w-full header-bg"
        ref={tableRef}
      >
        <div
          id="application-sidebar"
          className="hs-overlay hs-overlay-open:translate-x-0 -translate-x-full transition-all duration-300 transform hidden fixed inset-y-0 start-0 lg:z-30 z-60 w-64 bg-white border-e border-default-200 overflow-y-auto lg:block lg:translate-x-0 lg:end-auto lg:bottom-0 dark:bg-default-50 rtl:hs-overlay-open:translate-x-0 rtl:translate-x-full rtl:lg:translate-x-0 print:hidden "
        >
          <div 
          className="flex sticky top-0 items-center justify-center px-6 h-18 border-b border-dashed border-default-200"
          style={{backgroundColor:'#ffcc00'}}
          >
            <Link to="/admin">
              <img
                src="/assets/Ome1.png"
                alt="logo"
                className="h-12 flex dark:hidden"
              />
            </Link>
          </div>
          <div className="h-[calc(100%-72px)] p-4" data-simplebar>
            <ul className="admin-menu w-full flex flex-col gap-1.5">
              <li className="menu-item">
                <Link
                  className="flex items-center gap-x-3.5 py-3 px-5 text-sm font-medium text-default-700 rounded-md transition-all hover:bg-default-100"
                  to="/admin"
                >
                  <i className="ph-duotone ph-gauge text-xl" />
                  Dashboard
                </Link>
              </li>
              <div>
                <li className="menu-item">
                  <Link
                    onClick={() => setShowPartner(!showPartner)}
                    className=" flex items-center gap-x-3.5 py-3 px-5 text-sm font-medium text-default-700 rounded-md transition-all hover:bg-default-100"
                  >
                    <MdAdminPanelSettings />
                    Partner Management
                    <i className="ph-bold ph-caret-down text-base ms-auto transition-all hs-collapse-open:rotate-180" />
                  </Link>
                  {
                    showPartner ? (<>

                      <div
                      >
                        <ul className="space-y-2 mt-2 ms-4">
                          <li className="menu-item">
                            <Link
                              className="flex items-center gap-x-2.5 py-2 px-4 text-sm font-medium text-default-700 rounded-md hover:bg-default-100"
                              to="/admin/verifiedpartnerlist"
                            >
                              <IoMdPerson />
                              Verified Partners
                            </Link>
                          </li>
                          <li className="menu-item">
                            <Link
                              className="flex items-center gap-x-2.5 py-2 px-4 text-sm font-medium text-default-700 rounded-md hover:bg-default-100"
                              to="/admin/notverifiedpartnerlist"
                            >
                              <IoMdPerson />
                              Not-Verified Partners
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </>) : (<></>)
                  }
                </li>
              </div>
              <div>
                <li className="menu-item">
                  <Link
                    onClick={() => setShowVehicle(!showVehicle)}
                    className=" flex items-center gap-x-3.5 py-3 px-5 text-sm font-medium text-default-700 rounded-md transition-all hover:bg-default-100"
                  >
                    <MdAdminPanelSettings />
                    Vehicle Management
                    <i className="ph-bold ph-caret-down text-base ms-auto transition-all hs-collapse-open:rotate-180" />
                  </Link>
                  {
                    showVehicle ? (<>

                      <div
                      >
                        <ul className="space-y-2 mt-2 ms-4">
                          {/* <li className="menu-item">
                            <Link
                              className="flex items-center gap-x-2.5 py-2 px-4 text-sm font-medium text-default-700 rounded-md hover:bg-default-100"
                              to="/admin/addvehicle"
                            >
                              <IoMdPerson />
                              Add Vehicle
                            </Link>
                          </li> */}
                          <li className="menu-item">
                            <Link
                              className="flex items-center gap-x-2.5 py-2 px-4 text-sm font-medium text-default-700 rounded-md hover:bg-default-100"
                              to="/admin/vehiclelist"
                            >
                              <IoMdPerson />
                              Vehicle List
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </>) : (<></>)
                  }
                </li>
              </div>
              <div>
                <li className="menu-item">
                  <Link
                    onClick={() => setShowGoods(!showGoods)}
                    className=" flex items-center gap-x-3.5 py-3 px-5 text-sm font-medium text-default-700 rounded-md transition-all hover:bg-default-100"
                  >
                    <MdAdminPanelSettings />
                    Goods Management
                    <i className="ph-bold ph-caret-down text-base ms-auto transition-all hs-collapse-open:rotate-180" />
                  </Link>
                  {
                    showGoods ? (<>

                      <div
                      >
                        <ul className="space-y-2 mt-2 ms-4">
                          <li className="menu-item">
                            <Link
                              className="flex items-center gap-x-2.5 py-2 px-4 text-sm font-medium text-default-700 rounded-md hover:bg-default-100"
                              to="/admin/goodstype"
                            >
                              <IoMdPerson />
                              Add Goods Type
                            </Link>
                          </li>
                          <li className="menu-item">
                            <Link
                              className="flex items-center gap-x-2.5 py-2 px-4 text-sm font-medium text-default-700 rounded-md hover:bg-default-100"
                              to="/admin/goodstypelist"
                            >
                              <IoMdPerson />
                              Goods Type List
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </>) : (<></>)
                  }
                </li>
              </div>
              <div>
                <li className="menu-item">
                  <Link
                    onClick={() => setDrivingSchool(!drivingSchool)}
                    className=" flex items-center gap-x-3.5 py-3 px-5 text-sm font-medium text-default-700 rounded-md transition-all hover:bg-default-100"
                  >
                    <MdAdminPanelSettings />
                    Driving School Management
                    <i className="ph-bold ph-caret-down text-base ms-auto transition-all hs-collapse-open:rotate-180" />
                  </Link>
                  {
                    drivingSchool ? (<>

                      <div
                      >
                        <ul className="space-y-2 mt-2 ms-4">
                          <li className="menu-item">
                            <Link
                              className="flex items-center gap-x-2.5 py-2 px-4 text-sm font-medium text-default-700 rounded-md hover:bg-default-100"
                              to="/admin/driveschool/newrequests"
                            >
                              <IoCarSportOutline />
                              New Request
                            </Link>
                          </li>
                          <li className="menu-item">
                            <Link
                              className="flex items-center gap-x-2.5 py-2 px-4 text-sm font-medium text-default-700 rounded-md hover:bg-default-100"
                              to="/admin/driveschool/seenrequests"
                            >
                              <IoCarSportOutline />
                              Seen Request
                            </Link>
                          </li>
                          <li className="menu-item">
                            <Link
                              className="flex items-center gap-x-2.5 py-2 px-4 text-sm font-medium text-default-700 rounded-md hover:bg-default-100"
                              to="/admin/driveschool/deletedrequests"
                            >
                              <IoCarSportOutline />
                              Deleted Request
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </>) : (<></>)
                  }
                </li>
              </div>
              <div>
                <li className="menu-item">
                  <Link
                    onClick={() => setRentalService(!rentalService)}
                    className=" flex items-center gap-x-3.5 py-3 px-5 text-sm font-medium text-default-700 rounded-md transition-all hover:bg-default-100"
                  >
                    <MdAdminPanelSettings />
                    Rental Service Management
                    <i className="ph-bold ph-caret-down text-base ms-auto transition-all hs-collapse-open:rotate-180" />
                  </Link>
                  {
                    rentalService ? (<>

                      <div
                      >
                        <ul className="space-y-2 mt-2 ms-4">
                          <li className="menu-item">
                            <Link
                              className="flex items-center gap-x-2.5 py-2 px-4 text-sm font-medium text-default-700 rounded-md hover:bg-default-100"
                              to="/admin/vehicle-rental/newrequests"
                            >
                              <IoCarSportOutline />
                              New Request
                            </Link>
                          </li>
                          <li className="menu-item">
                            <Link
                              className="flex items-center gap-x-2.5 py-2 px-4 text-sm font-medium text-default-700 rounded-md hover:bg-default-100"
                              to="/admin/vehicle-rental/seenrequests"
                            >
                              <IoCarSportOutline />
                              Seen Request
                            </Link>
                          </li>
                          <li className="menu-item">
                            <Link
                              className="flex items-center gap-x-2.5 py-2 px-4 text-sm font-medium text-default-700 rounded-md hover:bg-default-100"
                              to="/admin/vehicle-rental/deletedrequests"
                            >
                              <IoCarSportOutline />
                              Deleted Request
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </>) : (<></>)
                  }
                </li>
              </div>
              <div>
                <li className="menu-item">
                  <Link
                    onClick={() => setRideService(!rideService)}
                    className=" flex items-center gap-x-3.5 py-3 px-5 text-sm font-medium text-default-700 rounded-md transition-all hover:bg-default-100"
                  >
                    <MdAdminPanelSettings />
                    Ride Service Management
                    <i className="ph-bold ph-caret-down text-base ms-auto transition-all hs-collapse-open:rotate-180" />
                  </Link>
                  {
                    rideService ? (<>
                      <div
                      >
                        <ul className="space-y-2 mt-2 ms-4">
                          <li className="menu-item">
                            <Link
                              className="flex items-center gap-x-2.5 py-2 px-4 text-sm font-medium text-default-700 rounded-md hover:bg-default-100"
                              to="/admin/rides/normalrides"
                            >
                              <IoCarSportOutline />
                              Normal Ride Request
                            </Link>
                          </li>
                          <li className="menu-item">
                            <Link
                              className="flex items-center gap-x-2.5 py-2 px-4 text-sm font-medium text-default-700 rounded-md hover:bg-default-100"
                              to="/admin/rides/goods-delivery-rides"
                            >
                              <IoCarSportOutline />
                              Goods Delivery Request
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </>) : (<></>)
                  }
                </li>
              </div>  
            </ul>
          </div>
        </div>
        <header className="sticky top-0 h-18 flex z-40 w-full border-b border-default-200/80 bg-white/80 backdrop-blur-3xl dark:bg-default-50 print:hidden" style={{backgroundColor:'#fff'}}>
          <nav className="flex items-center gap-4 w-full px-6">
            <div className="lg:hidden flex">
              <button
                type="button"
                className="text-default-500 hover:text-default-600"
                data-hs-overlay="#application-sidebar"
                aria-controls="application-sidebar"
                aria-label="Toggle navigation"
              >
                <i data-lucide="align-justify" className="w-6 h-6" />
              </button>
            </div>
            <div className="lg:hidden flex">
              <Link to="admin-dashboard.html">
                <img
                  src="/assets/logo.jpeg"
                  alt="logo"
                  className="h-10 w-full flex dark:hidden"
                />
              </Link>
            </div>
            {/* <div className="lg:flex hidden">
              <label htmlFor="icon" className="sr-only">
                Search
              </label>
              <div className="relative lg:flex hidden">
                <input
                  type="search"
                  className="ps-12 pe-4 py-2.5 block lg:w-64 border-none bg-default-100 text-default-700 rounded-lg text-sm focus:border-primary focus:ring-primary"
                  placeholder="Search for items..."
                />
                <span className="absolute start-4 top-2.5">
                  <i className="ph-duotone ph-magnifying-glass text-lg text-default-600" />
                </span>
              </div>
            </div> */}
            <div className="flex items-center gap-4 ms-auto">
              {/* <div
                className="flex"
                onClick={() => navigate("/admin/notifications")}
                style={{ position: "relative" }}
              >
                <CiBellOn style={{ height: "30px", width: "25px" }} />
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    backgroundColor: "#fff",
                  }}
                >
                  {unseenNotification.length}
                </div>
              </div> */}
              <div className="lg:flex hidden">
                <button
                  data-toggle="fullscreen"
                  onClick={handleFullScreen}
                  className="inline-flex flex-shrink-0 justify-center items-center gap-2 h-10 w-10 rounded-md font-medium text-default-700 align-middle bg-default-100 hover:text-primary transition-all text-xs"
                >
                  {!isFullScreen ? (
                    <>
                      <i className="ph-duotone ph-arrows-out text-2xl" />
                    </>
                  ) : (
                    <>
                      <i className="ph-duotone ph-arrows-in text-2xl" />
                    </>
                  )}
                </button>
              </div>
              <div className="flex">
                <div className="hs-dropdown relative inline-flex">
                  <button
                    id="hs-dropdown-with-header"
                    type="button"
                    className="hs-dropdown-toggle inline-flex justify-center items-center gap-2 flex-shrink-0 rounded-md font-medium text-default-700 align-middle transition-all text-xs"
                  >
                    <img
                      className="inline-block h-10 w-10 rounded-full"
                      src={loginData.avatar}
                      alt="Avatar"
                    />
                    <div className="lg:block text-start hidden">
                      <p
                        className="text-xs font-semibold text-default-700"
                        style={{ textTransform: "capitalize" }}
                      >
                        {loginData.name}
                      </p>
                      <p
                        className="text-xs text-default-500 mt-1"
                        style={{ textTransform: "capitalize" }}
                      >
                        {loginData.role}
                      </p>
                    </div>
                  </button>
                  <div className="hs-dropdown-menu mt-2 transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-[12rem] shadow-md rounded-lg p-2 border border-default-200 bg-white dark:bg-default-50">
                    <span
                      onClick={() => logoutClick()}
                      style={{ cursor: "pointer" }}
                      className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm font-medium text-red-400 hover:bg-red-400/10"
                    >
                      <i className="w-4 h-4" data-lucide="log-out" />
                      Log out
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </header>
      </div>
    </>
  );
};

export default Hadear;
