import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { json } from "react-router-dom";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import Hadear from "../../component/layout/Hadear";
import { act } from "react";

const initialState = {
  driveSchoolReqLoading: false,
  allRequests: [],
  newRequest: [],
  seenRequest: [],
  deletedRequest: [],
};
export const GetNewRequest = createAsyncThunk(
  "driveSchool/newRequests",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/drivingschool/all`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("");
    }
  }
);
export const updateRequest = createAsyncThunk(
  "driveSchool/updateRequest",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: {
          "content-type": "application/json",
        },
      };
      const url = `${Baseurl}/api/v1/drivingschool/update/${formData._id}`;
      const resp = await axios.put(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Request Not Able to update");
    }
  }
);
const driveSchoolSlice = createSlice({
  name: "driveSchool",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetNewRequest.pending, (state) => {
        state.driveSchoolReqLoading = true;
      })
      .addCase(GetNewRequest.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.allRequests = action.payload.drivingSchoolReqs;
          state.newRequest = state.allRequests.filter(
            (indRequest) => indRequest.status === 1
          );
          state.seenRequest = state.allRequests.filter(
            (indRequest) => indRequest.status === 2
          );
          state.deletedRequest = state.allRequests.filter(
            (indRequest) => indRequest.status === 3
          );
        }
        state.driveSchoolReqLoading = true;
      })
      .addCase(GetNewRequest.rejected, (state) => {
        state.driveSchoolReqLoading = false;
      })
      .addCase(updateRequest.pending, (state) => {
        state.driveSchoolReqLoading = true;
      })
      .addCase(updateRequest.fulfilled, (state, action) => {
        if (action.payload.sucess) {
          state.allRequests = state.allRequests.filter(
            (indReq) => indReq._id !== action.payload.drivingSchoolReq._id
          );
          state.allRequests = [
            action.payload.drivingSchoolReq,
            ...state.allRequests,
          ];
          state.newRequest = state.allRequests.filter(
            (indRequest) => indRequest.status === 1
          );
          state.seenRequest = state.allRequests.filter(
            (indRequest) => indRequest.status === 2
          );
          state.deletedRequest = state.allRequests.filter(
            (indRequest) => indRequest.status === 3
          );
        }
        state.driveSchoolReqLoading = true;
      })
      .addCase(updateRequest.rejected, (state) => {
        state.driveSchoolReqLoading = false;
      });
  },
});
export const driveSchoolActions = driveSchoolSlice.actions;
export default driveSchoolSlice.reducer;
