import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full" style={{ backgroundColor: '#ffcc00' }}>
        <footer className="p-6 border-t border-default-200 w-full bg-white dark:bg-default-50 mt-auto print:hidden" style={{ position: 'fixed', bottom: '0',backgroundColor:'#ffcc00' }}>
          <div className="flex flex-wrap items-center justify-between gap-6"
            style={{ backgroundColor: '#ffcc00' }}
          >
            <p className="text-default-600 font-semibold lg:text-start text-center">
              © 2024 DriveOme. All Rights Reserved
            </p>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
