import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { json } from "react-router-dom";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import { act } from "react";

const initialState = {
goodsDeliveryRides:[],
goodsDeliveryRideLoading:false,
normalRides:[],
normalRideLoading:false,
};

export const getAllGoodsDeliveryRequests = createAsyncThunk(
  "rentalRequests/all",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/order/all`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const normalRideRequests = createAsyncThunk(
  "rentalRequests/normalRideRequests",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/tour/all`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const RidesSlice = createSlice({
  name: "rides",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllGoodsDeliveryRequests.pending, (state, action) => {
        state.goodsDeliveryRideLoading = true;
      })
      .addCase(getAllGoodsDeliveryRequests.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.goodsDeliveryRides = action.payload.orders;
          state.goodsDeliveryRideLoading = false;
        }
      })
      .addCase(getAllGoodsDeliveryRequests.rejected, (state, action) => {
        state.goodsDeliveryRideLoading = true;
      })
      .addCase(normalRideRequests.pending, (state, action) => {
        state.normalRideLoading = true;
      })
      .addCase(normalRideRequests.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.normalRides =action.payload.Tours;
          state.normalRideLoading = false;
        }
      })
      .addCase(normalRideRequests.rejected, (state, action) => {
        state.normalRideLoading = true;
      });
  },
});
export const {} = RidesSlice.actions;
export default RidesSlice.reducer;
