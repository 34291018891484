import React from "react";
import Section from "./section";
import Section2 from "./Section2";

function Dashboard() {
  return (
    <div>
      <div style={{paddingBottom:'150px'}}>
        <Section />
      </div>
    </div>
  );
}

export default Dashboard;
