import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Switch } from "antd";

const DeletedDriveRequest = () => {
    const [filterData, setFilterData] = useState([]);
    const { deletedRequest } = useSelector((store) => store.driveSchool)

    useEffect(() => {
        if (deletedRequest) {
            setFilterData(deletedRequest)
        }
    }, [])
    return (
        <>
            <div className="min-h-screen flex flex-col lg:ps-64 w-full">
                <div className="p-6 space-y-6">
                    <div className="flex items-center justify-between w-full print:hidden">
                        <h4 className="text-lg font-semibold text-default-900">
                            Dashboard
                        </h4>
                    </div>
                </div>

                <div className="grid grid-cols-1">
                    <div className="shadow rounded-lg bg-white dark:bg-default-50" style={{ marginBottom: '50px' }}>
                        <div className="border-t border-dashed border-default-200">
                            <div className="relative overflow-x-auto">
                                <table className="min-w-full overflow-x-hidden">
                                    <thead className="border-b border-dashed border-default-200">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-16"
                                            >
                                                No
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-40"
                                            >
                                                Name
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-40"
                                            >
                                                Mobile
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-40"
                                            >
                                                Address
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-40"
                                            >
                                                Driving License
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-40"
                                            >
                                                drivingskills
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-40"
                                            >
                                                DOB
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-3 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-dashed divide-default-200">
                                        {filterData &&
                                            filterData
                                                .map((data, index) => (
                                                    <tr key={index}>
                                                        <td className="px-6 py-3 text-default-600 font-semibold whitespace-nowrap">
                                                            <b>{index + 1}</b>
                                                        </td>
                                                        <td className="px-6 py-3 whitespace-nowrap">
                                                            <h6 className="text-sm font-semibold text-default-700">
                                                                {data.firstName.length > 30 ? (
                                                                    <>{data.firstName.slice(0, 30)}...</>
                                                                ) : (
                                                                    <>{data.firstName.slice(0, 30)}</>
                                                                )}
                                                                    {data.lastName.length > 30 ? (
                                                                    <span style={{paddingLeft:'5px'}}>{data.lastName.slice(0, 30)}...</span>
                                                                ) : (
                                                                    <span span style={{paddingLeft:'5px'}}>{data.lastName.slice(0, 30)}</span>
                                                                )}
                                                            </h6>
                                                        </td>
                                                        <td className="px-6 py-3 text-default-600 font-semibold whitespace-nowrap">
                                                            <b>{data?.mobile}</b>
                                                        </td>
                                                        <td className="px-6 py-3 text-default-600 font-semibold whitespace-nowrap">
                                                            <b>{data?.address}</b>
                                                        </td>
                                                        <td className="px-6 py-3 text-default-600 font-semibold whitespace-nowrap">
                                                            <b>{data?.drivinglicense}</b>
                                                        </td>
                                                        <td className="px-6 py-3 text-default-600 font-semibold whitespace-nowrap">
                                                            <b>{data?.drivingskills}</b>
                                                        </td>
                                                        <td className="px-6 py-3 text-default-600 font-semibold whitespace-nowrap">
                                                            <b>{data?.dateofbirth}</b>
                                                        </td>
                                                        <td className="whitespace-nowrap py-3 px-3 text-center text-sm font-medium">
                                                            <div className="flex items-center justify-center gap-2">
                                                                <button className="inline-flex items-center justify-center h-8 w-8 rounded-md bg-cyan-500/20 text-cyan-500 transition-all duration-200 hover:bg-cyan-500 hover:text-white">
                                                                    <Link
                                                                        to={`/admin/updategoodstype/${data._id}`}
                                                                    >
                                                                        <i className="ph-duotone ph-pencil-simple-line text-base" />
                                                                    </Link>
                                                                </button>
                                                                {/* <button className="inline-flex items-center justify-center h-8 w-8 rounded-md bg-red-500/20 text-red-500 transition-all duration-200 hover:bg-red-500 hover:text-white">
                                  <i className="ph-duotone ph-trash text-base" />
                                </button> */}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="flex flex-wrap items-center justify-between gap-6 py-3 px-6 border-t border-dashed border-default-200" >
                            <h6 className="text-default-600">Showing {filterData.length} results </h6>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DeletedDriveRequest;
