import React, { useEffect, useState, useCallback } from 'react';
import { GoogleMap, useJsApiLoader, MarkerF, Polyline } from '@react-google-maps/api';
import { api_key } from '../../config/BaseUrl';

const containerStyle = {
    width: '100%',
    height: '600px'
};

const defaultCenter = {
    lat: -3.745,
    lng: -38.523
};

function ShowAllVerifiedPartner({ data }) {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: api_key
    });

    const [points, setPoints] = useState([]);
    console.log(points, 'points');

    useEffect(() => {
        if (data) {
            const point = data.map((item, index) =>
                setPoints([...points, {
                    lat: parseFloat(item.lat),
                    lng: parseFloat(item.lang),
                }])
            )
        }
    }, [data])

    const [map, setMap] = useState(null);

    const onLoad = useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds();
        points.forEach(point => bounds.extend(point)); // Adjust bounds to fit markers
        map.fitBounds(bounds);

        setMap(map);
    }, [points]);
    const onUnmount = useCallback(function callback(map) {
        setMap(null);
    }, []);
    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={defaultCenter}
            zoom={10}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            {/* Place markers for origin and destination */}
            {
                points.map((point, index) => (
                    <MarkerF
                        key={index}
                        position={point}
                    />
                ))
            }
        </GoogleMap>
    ) : <></>;
}

export default React.memo(ShowAllVerifiedPartner);
