import React, { useEffect, useState, useCallback } from 'react';
import { GoogleMap, useJsApiLoader, MarkerF,Polyline } from '@react-google-maps/api';
import { api_key } from '../../config/BaseUrl';

const containerStyle = {
    width: '100%',
    height: '400px'
};

const defaultCenter = {
    lat: -3.745,
    lng: -38.523
};

function ShowMap({ data }) {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: api_key
    });

    const [points, setPoints] = useState([]);

    // Set points based on ride data (origin and destination)
    useEffect(() => {
        if (data && data.originCode && data.destinationCode) {
            setPoints([
                {
                    lat: parseFloat(data.originCode.lat),
                    lng: parseFloat(data.originCode.lng),
                    icon: '/assets/green.png'
                },
                {
                    lat: parseFloat(data.destinationCode.lat),
                    lng: parseFloat(data.destinationCode.lng),
                    icon: '/assets/pinicon.png'
                }
            ]);
        }
    }, [data]);

    const [map, setMap] = useState(null);

    const onLoad = useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds();
        points.forEach(point => bounds.extend(point)); // Adjust bounds to fit markers
        map.fitBounds(bounds);

        setMap(map);
    }, [points]);

    const onUnmount = useCallback(function callback(map) {
        setMap(null);
    }, []);

        // Path coordinates to connect the origin and destination
        const pathCoordinates = points.map(point => ({
            lat: point.lat,
            lng: point.lng
        }));
    
        // Polyline options (optional)
        const polylineOptions = {
            strokeColor: "#FF0000",
            strokeOpacity: 1.0,
            strokeWeight: 4,
        };

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={defaultCenter}
            zoom={10}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            {/* Place markers for origin and destination */}
            {
                points.map((point, index) => (
                    <MarkerF
                        key={index}
                        position={point}
                        icon={{
                            url: point.icon, // URL of the custom marker image
                            scaledSize: new window.google.maps.Size(50, 50), // Size of the custom icon
                        }}
                    />
                ))
            }
        </GoogleMap>
    ) : <></>;
}

export default React.memo(ShowMap);
