import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Modal, Button } from "antd";
import { updateRentalRequest } from "../../redux/VehicleRental/VehicleRentalSlice";
import { Switch } from "antd";
const RentalRequest = () => {
    const [filterData, setFilterData] = useState([]);
    const { newRentalRequest } = useSelector((store) => store.vehicleRental)
    const dispatch = useDispatch()

    const [isProcessModalOpen, setIsProcessModalOpen] = useState(false);
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState('')

    useEffect(() => {
        if (newRentalRequest) {
            setFilterData(newRentalRequest)
        }
    }, [newRentalRequest])

    const handleProcess = async () => {
        const formData = {
            _id: selectedRequest._id,
            status: 2,
            statustext: "Request has been processed"
        }
        const getResult = await dispatch(updateRentalRequest(formData))
        if (getResult.payload.success) {
            setIsProcessModalOpen(false);
            window.location.reload();
        }
    }
    const showModal = (data) => {
        setSelectedRequest(data)
        setIsProcessModalOpen(true);
    };

    const handleOk = () => {
        setIsProcessModalOpen(false);
    };

    const handleCancel = () => {
        setIsProcessModalOpen(false);
    };
    const showCancelModal = (data) => {
        setSelectedRequest(data)
        setIsCancelModalOpen(true);
    };

    const handleButtonOk = () => {
        setIsCancelModalOpen(false);
    };

    const handleButtonCancel = () => {
        setIsCancelModalOpen(false);
    };
    const handleDelete = async (data) => {
        const formData = {
            _id: selectedRequest._id,
            status: 3,
            statustext: "Request has been cancelled"
        }
        const getResult = await dispatch(updateRentalRequest(formData))
        if (getResult.payload.success) {
            setIsCancelModalOpen(false);
            window.location.reload();
        }
    }
    return (
        <>
            <div className="min-h-screen flex flex-col lg:ps-64 w-full">
                <div className="p-6 space-y-6">
                    <div className="flex items-center justify-between w-full print:hidden">
                        <h4 className="text-lg font-semibold text-default-900">
                            Dashboard
                        </h4>
                    </div>
                </div>

                <div className="grid grid-cols-1">
                    <div className="shadow rounded-lg bg-white dark:bg-default-50" style={{ marginBottom: '50px' }}>
                        <div className="border-t border-dashed border-default-200">
                            <div className="relative overflow-x-auto">
                                <table className="min-w-full overflow-x-hidden">
                                    <thead className="border-b border-dashed border-default-200">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-16"
                                            >
                                                No
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-40"
                                            >
                                                Name
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-40"
                                            >
                                                Mobile
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-40"
                                            >
                                                Address
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-40"
                                            >
                                                Date Of Requirement
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-40"
                                            >
                                                Vehicle Type
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-40"
                                            >
                                                Need Driver
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-3 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Action
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-3 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-dashed divide-default-200">
                                        {filterData &&
                                            filterData
                                                .map((data, index) => (
                                                    <tr key={index}>
                                                        <td className="px-6 py-3 text-default-600 font-semibold whitespace-nowrap">
                                                            <b>{index + 1}</b>
                                                        </td>
                                                        <td className="px-6 py-3 whitespace-nowrap">
                                                            <h6 className="text-sm font-semibold text-default-700">
                                                                {data.name.length > 30 ? (
                                                                    <>{data.name.slice(0, 30)}...</>
                                                                ) : (
                                                                    <>{data.name.slice(0, 30)}</>
                                                                )}
                                                            </h6>
                                                        </td>
                                                        <td className="px-6 py-3 text-default-600 font-semibold whitespace-nowrap">
                                                            <b>{data?.phone}</b>
                                                        </td>
                                                        <td className="px-6 py-3 text-default-600 font-semibold whitespace-nowrap">
                                                            <b>{data?.address}</b>
                                                        </td>
                                                        <td className="px-6 py-3 text-default-600 font-semibold whitespace-nowrap">
                                                            <b>{data?.requiredDate}</b>
                                                        </td>
                                                        <td className="px-6 py-3 text-default-600 font-semibold whitespace-nowrap">
                                                            <b>{data?.vehicle}</b>
                                                        </td>
                                                        <td className="px-6 py-3 text-default-600 font-semibold whitespace-nowrap">
                                                            <Switch disabled={true} defaultChecked={data?.needDriver ? true : false} />
                                                        </td>
                                                        <td className="whitespace-nowrap py-3 px-3 text-center text-sm font-medium">
                                                            <div className="flex items-center justify-center gap-2">
                                                                <button
                                                                    className="inline-flex items-center justify-center h-8 w-18 rounded-md bg-cyan-500/20 text-cyan-500 transition-all duration-200 hover:bg-cyan-500 hover:text-white"
                                                                    onClick={() => showModal(data)}
                                                                >
                                                                    Proceed
                                                                </button>
                                                            </div>
                                                        </td>
                                                        <td className="whitespace-nowrap py-3 px-3 text-center text-sm font-medium">
                                                            <div className="flex items-center justify-center gap-2">
                                                                <button
                                                                    className="inline-flex items-center justify-center h-8 w-18 rounded-md bg-cyan-500/20 text-cyan-500 transition-all duration-200 hover:bg-cyan-500 hover:text-white"
                                                                    onClick={() => showCancelModal(data)}
                                                                >
                                                                    Delete
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <Modal title="Basic Modal"
                            open={isProcessModalOpen}
                            onOk={handleOk}
                            footer={[
                                <Button
                                    style={{
                                        padding: "10px 15px",
                                        color: "#fff",
                                        backgroundColor: "#FF0000",
                                    }}
                                    onClick={() => handleCancel(false)}
                                >
                                    Cancel
                                </Button>,
                                <Button
                                    style={{
                                        padding: "10px 15px",
                                        color: "#fff",
                                        backgroundColor: "#04681e",
                                    }}
                                    onClick={(e) => handleProcess(e)}
                                >
                                    Confirm
                                </Button>,
                            ]}
                        >
                            <p>Do you want to Process {selectedRequest.name}'s request</p>
                        </Modal>
                        <Modal title="Basic Modal"
                            open={isCancelModalOpen}
                            onOk={handleButtonOk}
                            footer={[
                                <Button
                                    style={{
                                        padding: "10px 15px",
                                        color: "#fff",
                                        backgroundColor: "#FF0000",
                                    }}
                                    onClick={() => handleButtonCancel(false)}
                                >
                                    Cancel
                                </Button>,
                                <Button
                                    style={{
                                        padding: "10px 15px",
                                        color: "#fff",
                                        backgroundColor: "#04681e",
                                    }}
                                    onClick={(e) => handleDelete(e)}
                                >
                                    Confirm
                                </Button>,
                            ]}
                        >
                            <p>Do you want to Delete {selectedRequest.firstName}'s request</p>
                        </Modal>
                        <div className="flex flex-wrap items-center justify-between gap-6 py-3 px-6 border-t border-dashed border-default-200" >
                            <h6 className="text-default-600">Showing {filterData.length} results </h6>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RentalRequest;
