import React, { useEffect, useState } from "react";
import { FaShoppingBasket } from "react-icons/fa";
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";
import { BsBagFill } from "react-icons/bs";
import { AiFillDollarCircle } from "react-icons/ai";
import { MdArrowDropDown } from "react-icons/md";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useSelector } from "react-redux";
import axios from "axios";

const data = [
  { name: "Page A", uv: 4000 },
  { name: "Page B", uv: 3000 },
  { name: "Page C", uv: 2000 },
  { name: "Page D", uv: 2780 },
  { name: "Page E", uv: 1890 },
  { name: "Page F", uv: 2390 },
  { name: "Page G", uv: 3490 },
];

const Section = () => {
  const { newRequest } = useSelector((store) => store.driveSchool);
  const { newRentalRequest } = useSelector((store) => store.vehicleRental);
  const { goodsDeliveryRides, normalRides } = useSelector((store) => store.rides);
  const { verifiedPartner, notVerifiedPartner } = useSelector((store) => store.partner);
  const [goodsRideByDate, setGoodsRideByDate] = useState([])
  const [normalRideByDate, setNormalRideByDate] = useState([])
  useEffect(() => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1; // getMonth() is zero-indexed

    const extractDate = (isoString) => isoString.split('T')[0];
    const parseCost = (costString) => parseFloat(costString.replace('₹', ''));

    // Filter to include only rides from the current month and year
    const filteredRides = normalRides.filter((ride) => {
      const rideDate = new Date(ride.createdAt);
      const rideYear = rideDate.getFullYear();
      const rideMonth = rideDate.getMonth() + 1;
      return rideYear === currentYear && rideMonth === currentMonth;
    });

    const result = filteredRides.reduce((acc, current) => {
      const dateOnly = extractDate(current.createdAt);
      const travelCost = parseCost(current.travelCost);
      const existingDate = acc.find(item => item.date === dateOnly);

      if (existingDate) {
        existingDate.totalEarningOfTheDay += travelCost;
      } else {
        acc.push({ date: dateOnly, totalEarningOfTheDay: travelCost });
      }

      return acc;
    }, []);

    setNormalRideByDate(result);
  }, [normalRides])

  useEffect(() => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1; // getMonth() is zero-indexed, so we add 1

    const extractDate = (isoString) => isoString.split('T')[0];
    const parseCost = (costString) => parseFloat(costString);

    // Filter to include only rides from the current month and year
    const filteredRides = goodsDeliveryRides.filter((ride) => {
      const rideDate = new Date(ride.createdAt);
      const rideYear = rideDate.getFullYear();
      const rideMonth = rideDate.getMonth() + 1;
      return rideYear === currentYear && rideMonth === currentMonth;
    });

    // Reduce to sum the travel cost by date
    const result = filteredRides.reduce((acc, current) => {
      const dateOnly = extractDate(current.createdAt);
      const travelCost = parseCost(current.travelCost);
      const existingDate = acc.find(item => item.date === dateOnly);

      if (existingDate) {
        existingDate.totalTravelCost += travelCost;
      } else {
        acc.push({ date: dateOnly, totalEarningOfTheDay: travelCost });
      }

      return acc;
    }, []);

    setGoodsRideByDate(result);
  }, [goodsDeliveryRides]);


  const items = [
    {
      icon: <FaShoppingBasket style={{ color: "#4662f3", fontSize: "26px" }} />,
      title: "Total Orders",
      count: "75",
      percentage: "3%",
      color: "#000",
      backgroundColor: "#329cde",
    },
    {
      icon: <BsBagFill style={{ color: "#02c88b", fontSize: "24px" }} />,
      title: "Total Delivered",
      count: "70",
      percentage: "3%",
      color: "#000",
      backgroundColor: "#175348",
    },
    {
      icon: <BsBagFill style={{ color: "#f85e62", fontSize: "24px" }} />,
      title: "Total Cancelled",
      count: "05",
      percentage: "3%",
      color: "#000",
      backgroundColor: "#63323c",
    },
    {
      icon: <AiFillDollarCircle style={{ color: "#da4f9b", fontSize: "24px" }} />,
      title: "Total Revenue",
      count: "$12k",
      percentage: "3%",
      color: "#000",
      backgroundColor: "#632c50",
    },
  ];

  return (
    <div>
      <div className="heading p-3">
        <h5 className="dash">Dashboard</h5>
      </div>
      <div
        className="grid md:grid-cols-2 xl:grid-cols-4 gap-6"
        style={{ marginBottom: "60px", padding: '10px' }}
      >
        <div
          className="shadow rounded-lg bg-white dark:bg-default-50 dashboard-back"
          style={{ backgroundColor: "#fff", boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}
        >
          <div className="p-5">
            <div>
              <div className="mb-1">
                <span
                  className="text-base font-semibold text-default-900"
                  style={{ color: "#000" }}
                >
                  All Partners :
                </span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="mb-1">
                  <div>
                    <span
                      className="text-base font-semibold text-default-900"
                      style={{ color: "#000" }}
                    >
                      Verified Partners :
                    </span>
                    <span
                      className="inline-block ps-3"
                      style={{ color: "#000", fontWeight: "600" }}
                    >
                      {verifiedPartner.length}
                    </span>
                  </div>
                  <div>
                    <span
                      className="text-base font-semibold text-default-900"
                      style={{ color: "#000" }}
                    >
                      Not-Verified Partners :
                    </span>
                    <span
                      className="inline-block ps-3"
                      style={{ color: "#000", fontWeight: "600" }}
                    >
                      {notVerifiedPartner.length}
                    </span>
                  </div>
                </div>
                <span
                  className="bg-default-200 rounded-lg flex items-center justify-center h-10 w-10 dark:bg-default-200/80"
                  style={{ overflow: "hidden" }}
                >
                  <img
                    src="https://res.cloudinary.com/dexterous-technology/image/upload/v1726296995/shocked-guy-with-helmet-driving-yellow-scooter_273609-31420_jmnv6o.png"
                    alt
                    style={{
                      height: "100%",
                      width: "100%",
                      objectPosition: "center",
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          className="shadow rounded-lg bg-white dark:bg-default-50 dashboard-back"
          style={{ backgroundColor: "#fff", boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}
        >
          <div className="p-5">
            <div>
              <div className="mb-1">
                <span
                  className="text-base font-semibold text-default-900"
                  style={{ color: "#000" }}
                >
                  All Rides :
                </span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="mb-1">
                  <div>
                    <span
                      className="text-base font-semibold text-default-900"
                      style={{ color: "#000" }}
                    >
                      Normal Rides :
                    </span>
                    <span
                      className="inline-block ps-3"
                      style={{ color: "#000", fontWeight: "600" }}
                    >
                      {normalRides.length}
                    </span>
                  </div>
                  <div>
                    <span
                      className="text-base font-semibold text-default-900"
                      style={{ color: "#000" }}
                    >
                      Goods Deliveries :
                    </span>
                    <span
                      className="inline-block ps-3"
                      style={{ color: "#000", fontWeight: "600" }}
                    >
                      {goodsDeliveryRides.length}
                    </span>
                  </div>
                </div>
                <span
                  className="bg-default-200 rounded-lg flex items-center justify-center h-10 w-10 dark:bg-default-200/80"
                  style={{ overflow: "hidden" }}
                >
                  <img
                    src="https://res.cloudinary.com/dexterous-technology/image/upload/v1726296995/shocked-guy-with-helmet-driving-yellow-scooter_273609-31420_jmnv6o.png"
                    alt
                    style={{
                      height: "100%",
                      width: "100%",
                      objectPosition: "center",
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          className="shadow rounded-lg bg-white dark:bg-default-50 dashboard-back"
          style={{ backgroundColor: "#fff", boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}
        >
          <div className="p-5">
            <div>
              <div className="mb-1">
                <span
                  className="text-base font-semibold text-default-900"
                  style={{ color: "#000" }}
                >
                  Driving School Requests :
                </span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="mb-1">
                  <div>
                    <span
                      className="text-base font-semibold text-default-900"
                      style={{ color: "#000" }}
                    >
                      New Requests :
                    </span>
                    <span
                      className="inline-block ps-3"
                      style={{ color: "#000", fontWeight: "600" }}
                    >
                      {newRequest.length}
                    </span>
                  </div>
                </div>
                <span
                  className="bg-default-200 rounded-lg flex items-center justify-center h-10 w-10 dark:bg-default-200/80"
                  style={{ overflow: "hidden" }}
                >
                  <img
                    src="https://res.cloudinary.com/dexterous-technology/image/upload/v1726296995/shocked-guy-with-helmet-driving-yellow-scooter_273609-31420_jmnv6o.png"
                    alt
                    style={{
                      height: "100%",
                      width: "100%",
                      objectPosition: "center",
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          className="shadow rounded-lg bg-white dark:bg-default-50 dashboard-back"
          style={{ backgroundColor: "#fff", boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}
        >
          <div className="p-5">
            <div>
              <div className="mb-1">
                <span
                  className="text-base font-semibold text-default-900"
                  style={{ color: "#000" }}
                >
                  Rental Service Requests :
                </span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="mb-1">
                  <div>
                    <span
                      className="text-base font-semibold text-default-900"
                      style={{ color: "#000" }}
                    >
                      New Requests :
                    </span>
                    <span
                      className="inline-block ps-3"
                      style={{ color: "#000", fontWeight: "600" }}
                    >
                      {newRentalRequest.length}
                    </span>
                  </div>
                </div>
                <span
                  className="bg-default-200 rounded-lg flex items-center justify-center h-10 w-10 dark:bg-default-200/80"
                  style={{ overflow: "hidden" }}
                >
                  <img
                    src="https://res.cloudinary.com/dexterous-technology/image/upload/v1726296995/shocked-guy-with-helmet-driving-yellow-scooter_273609-31420_jmnv6o.png"
                    alt
                    style={{
                      height: "100%",
                      width: "100%",
                      objectPosition: "center",
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="chart">
        <div className="d-flex justify-content-between align-items-center p-2">
          <div>
            <h5 style={{ textAlign: 'center', fontWeight: '700' }}>Normal Rides Activity</h5>
          </div>

        </div>
        {
          normalRideByDate.length > 0 &&
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={normalRideByDate}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="totalEarningOfTheDay" fill="#6439FF" />
            </BarChart>
          </ResponsiveContainer>
        }
      </div>
      <div className="chart">
        <div className="d-flex justify-content-between align-items-center p-2">
          <div>
            <h5 style={{ textAlign: 'center', fontWeight: '700' }}>Goods Delivery Activity</h5>
          </div>

        </div>
        {
          goodsRideByDate.length > 0 &&
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={goodsRideByDate}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="totalEarningOfTheDay" fill="#6439FF" />
            </BarChart>
          </ResponsiveContainer>
        }
      </div>

      <div className="recent">
        <div className="recent-heading">
          <h5 style={{ textAlign: 'center', fontWeight: '700' }}>Recent Normal Rides</h5>
        </div>
        <div>
          <table className="custom-table">
            <thead>
              <tr>
                <th>Customer</th>
                <th>Origin Address</th>
                <th>Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {
                normalRides && normalRides.slice(0, 5).map((indRides, index) =>
                  <tr key={index}>
                    <td>{indRides?.travellerName}</td>
                    <td>{indRides?.origin}</td>
                    <td>{indRides?.travelCost}</td>
                    <td><span className="status delivered">{indRides?.status}</span></td>
                  </tr>
                )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="recent">
        <div className="recent-heading">
          <h5 style={{ textAlign: 'center', fontWeight: '700' }}>Recent Goods Delivery Rides</h5>
        </div>
        <div>
          <table className="custom-table">
            <thead>
              <tr>
                <th>Customer</th>
                <th>Origin Address</th>
                <th>Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {
                goodsDeliveryRides && goodsDeliveryRides.slice(0, 5).map((indRides, index) =>
                  <tr>
                    <td>{indRides?.pickupName}</td>
                    <td>{indRides?.origin}</td>
                    <td>{indRides?.travelCost}</td>
                    <td><span className="status delivered">{indRides?.status}</span></td>
                  </tr>
                )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Section;
