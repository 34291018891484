import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { json } from "react-router-dom";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import { act } from "react";

const initialState = {
  allRentalRequests: [],
  newRentalRequest: [],
  seenRentalRequests: [],
  cancelledRentalRequests: [],
  rentalRequestLoading: true,
};

export const getAllRentalRequests = createAsyncThunk(
  "rentalRequests/all",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/rental/all`;
      const res = await axios.get(url);
      console.log(res.data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const updateRentalRequest = createAsyncThunk(
  "rentalRequests/update",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: {
          "content-type": "application/json",
        },
      };
      const url = `${Baseurl}/api/v1/rental/update/${formData._id}`;
      const res = await axios.put(url, formData, config);
      console.log(res.data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const vehicleRentalSlice = createSlice({
  name: "vehicleRental",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllRentalRequests.pending, (state, action) => {
        state.rentalRequestLoading = true;
      })
      .addCase(getAllRentalRequests.fulfilled, (state, action) => {
        if (action.payload.success) {
          // console.log(action.payload.rentalRequests,'action.payload.rentalRequests');
          // state.allRentalRequests = action.payload.rentalRequests;
          // state.newRentalRequest = action.payload.rentalRequests.filter(
          //   (indRequest) => indRequest.status === 1
          // );
          // state.seenRentalRequests = state.allRentalRequests.filter(
          //   (indRequest) => indRequest.status === 2
          // );
          // state.cancelledRentalRequests = state.allRentalRequests.filter(
          //   (indRequest) => indRequest.status === 3
          // );
          state.rentalRequestLoading = false;
        }
      })
      .addCase(getAllRentalRequests.rejected, (state, action) => {
        state.rentalRequestLoading = true;
      })
      .addCase(updateRentalRequest.pending, (state, action) => {
        state.rentalRequestLoading = true;
      })
      .addCase(updateRentalRequest.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.allRentalRequests = state.allRentalRequests.filter(
            (indRequest) => indRequest._id !== action.payload.rentalRequest._id
          );
          if (action.payload.rentalRequest.status === 1) {
            state.newRentalRequest = [
              action.payload.rentalRequest,
              ...state.newRentalRequest,
            ];
          } else if (action.payload.rentalRequest.status === 2) {
            state.seenRentalRequests = [
              action.payload.rentalRequest,
              ...state.seenRentalRequests,
            ];
          } else {
            state.cancelledRentalRequests = [
              action.payload.rentalRequest,
              ...state.cancelledRentalRequests,
            ];
          }
          state.rentalRequestLoading = false;
        }
      })
      .addCase(updateRentalRequest.rejected, (state, action) => {
        state.rentalRequestLoading = true;
      });
  },
});
export const {} = vehicleRentalSlice.actions;
export default vehicleRentalSlice.reducer;
